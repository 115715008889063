<template>
    <div>
        <div class="panel-style">
            <el-form :inline="true" :model="searchDto" size="mini">
                <el-form-item label="参数主键">
                    <el-input v-model="searchDto.sysParamKey" clearable autocomplete="off" placeholder="参数主键"/>
                </el-form-item>
                <el-form-item label="参数名称">
                    <el-input v-model="searchDto.sysParamName" clearable autocomplete="off" placeholder="参数名称"/>
                </el-form-item>
                <el-form-item label="参数值">
                    <el-input v-model="searchDto.sysParamValue" clearable autocomplete="off" placeholder="参数值"/>
                </el-form-item>
                <el-form-item label="备注">
                    <el-input v-model="searchDto.remark" clearable autocomplete="off" placeholder="备注"/>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="handleQueryClickEvent">查询</el-button>
                    <el-button type="primary" @click="openSysParamConfigDetailPage(null)">新增</el-button>
                </el-form-item>
            </el-form>
            <el-table border stripe size="small" :data="tableData" :header-cell-style="{background:'#eef1f6',color:'#606266'}"
                      max-height="550px" v-loading="listLoading">
                <el-table-column prop="sysParamKey" :show-overflow-tooltip="true" label="参数主键" width="200px"/>
                <el-table-column prop="sysParamName" :show-overflow-tooltip="true" label="参数名称" width="200px"/>
                <el-table-column prop="sysParamValue" :show-overflow-tooltip="true" label="参数值"/>
                <el-table-column prop="remark" :show-overflow-tooltip="true" label="备注" width="230px"/>
                <el-table-column prop="createTime" label="创建时间" align="center" width="150px"/>
                <el-table-column prop="updateTime" label="更新时间" align="center" width="150px"/>
                <el-table-column label="操作" align="center" width="80">
                    <template v-slot="scope">
                        <div class="option-btn-div">
                            <el-tooltip v-delTabIndex effect="dark" content="编辑" placement="top">
                                <i class="el-icon-edit option-btn-normal" @click="openSysParamConfigDetailPage(scope.row.id)"/>
                            </el-tooltip>
                            <el-tooltip v-delTabIndex effect="dark" content="删除" placement="top">
                                <i class="el-icon-delete option-btn-normal" style="color: #F56C6C" @click="removeSysParam(scope.row)"/>
                            </el-tooltip>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
            <div class="pagination">
                <el-pagination
                        background
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="tableTotal"
                        :current-page.sync="searchDto.pageNum"
                        :page-sizes="[10, 50, 100]"
                        :page-size="searchDto.pageSize"
                        @size-change="handleSizeChangeEvent"
                        @current-change="handleCurrentChangeEvent"/>
            </div>
            <sys-param-detail ref="sysParamConfigDetailPage" @querySysParamList="querySysParamList"/>
        </div>
    </div>
</template>

<script>
import message from '@/utils/message';
import {querySysParamList, removeSysParam} from '@/api/sys/param/sysParamConfigApi';
import SysParamDetail from '@/views/sys/param/sys-param-detail';
import global from '@/components/Global';
export default {

    name: 'SysParamList',
    components: {

        SysParamDetail
    },
    data() {

        return {

            searchDto: {

                pageNum: 1,
                pageSize: 10,
                sysParamKey: '',
                sysParamName: '',
                sysParamValue: '',
                remark: ''
            },
            listLoading: false,
            tableData: [],
            tableTotal: 0,
            detailDialogVisible: false
        }
    },
    methods: {

        initSysParamListPage() {

            this.querySysParamList();
        },
        handleCurrentChangeEvent(val) {

            this.searchDto.pageNum = val;
            this.querySysParamList();
        },
        handleSizeChangeEvent(val) {

            this.searchDto.pageSize = val;
            this.querySysParamList();
        },
        handleQueryClickEvent() {

            this.searchDto.pageNum = 1;
            this.querySysParamList();
        },
        querySysParamList() {

            this.listLoading = true;
            querySysParamList(this.searchDto).then((res) => {

                this.listLoading = false;
                if (res.code === global.response_status_success_obj) {

                    this.tableTotal = res.data.total;
                    this.tableData = res.data.result;
                } else {

                    message.error(res.msg);
                }
            }).catch(err => {

                message.error('请求失败,请稍后再试');
                console.error(err);
                this.listLoading = false;
            });
        },
        openSysParamConfigDetailPage(id) {

            this.$refs.sysParamConfigDetailPage.initSysParamDetailPage(id);
        },
        removeSysParam(row) {

            this.$confirm('是否需要删除？', '提示', {type: 'warning'})
                .then(() => {

                    removeSysParam({id : row.id}).then((res) => {

                        if (res.code === global.response_status_success_obj) {

                            message.success('删除成功');
                        } else {

                            message.error(res.msg);
                        }
                        this.querySysParamList();
                    }).catch(err => {

                        message.error('请求失败,请稍后再试');
                        console.error(err);
                    });
                }
            );
        },
    },
    mounted() {

        this.initSysParamListPage();
    }
}
</script>

<style lang="scss" scoped>
.pagination {

    margin-top: 15px;
    text-align: right;
}
.option-btn-div {

    display: flex;
    justify-content: space-around
}
.option-btn-normal {

    font-size: 18px;
    color: #409EFF;
    cursor: pointer;
}
</style>
