import axios from 'axios';
import global from '@/components/Global.vue';

let base = global.serverSite + '/doartIot/doart-system';

// 分页查询系统参数
export const querySysParamList = params => { return axios.post(base + '/param/querySysParamList', params)};

// 添加/修改系统参数
export const saveOrModifySysParam = params => { return axios.post(base + '/param/saveOrModifySysParam', params)};

// 查询系统参数
export const querySysParamById = params => { return axios.post(base + '/param/querySysParamById', params)};

// 删除系统参数
export const removeSysParam = params => { return axios.post(base + '/param/removeSysParam', params)};
