<template>
    <el-dialog :title="detailDialogTitle" :visible.sync="detailDialogVisible" top="0"
           @close="closeSysParamConfigDetailPage" width="850px" :close-on-click-modal="false" center>
        <el-form :model="sysParamDto" :rules="formRules" ref="sysParamConfigForm"
             label-width="120px" size="mini">
            <el-row>
                <el-col :span="10">
                    <el-form-item label="参数主键" prop="sysParamKey">
                        <el-input :disabled="sysParamDto.id !== null" v-model="sysParamDto.sysParamKey"
                              autocomplete="off" maxlength="255"/>
                    </el-form-item>
                </el-col>
                <el-col :span="10" :offset="2">
                    <el-form-item label="参数名称" prop="sysParamName">
                        <el-input v-model="sysParamDto.sysParamName" autocomplete="off" maxlength="50"/>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="22">
                    <el-form-item label="参数值" prop="sysParamValue">
                        <el-input v-model="sysParamDto.sysParamValue" autocomplete="off" :show-word-limit="true"
                              maxlength="150" type="textarea" :rows="4"/>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="22">
                    <el-form-item label="备注" prop="remark">
                        <el-input v-model="sysParamDto.remark" autocomplete="off" :show-word-limit="true"
                              maxlength="250" type="textarea" :rows="4"/>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button type="primary" @click="saveOrModifySysParam" size="mini">确定</el-button>
            <el-button @click="detailDialogVisible = false" size="mini">取消</el-button>
        </div>
    </el-dialog>
</template>
<script>
import message from '@/utils/message';
import {querySysParamById, saveOrModifySysParam} from '@/api/sys/param/sysParamConfigApi';
import global from '@/components/Global';
import loading from '@/utils/cmdLoading';
export default {

    name: 'SysParamConfigDetail',
    data() {

        return {

            detailDialogVisible: false,
            detailDialogTitle: '',
            jsonSchema: '',
            sysParamDto: {

                id: null,
                sysParamKey: null,
                sysParamName: null,
                sysParamValue: null,
                remark: null
            },
            // 后端接口返回的校验不通过字段
            validateNotPassField: [],
            formRules: {

                sysParamKey: [
                    {required: true, message: '参数主键不能为空', trigger: 'blur'}
                ],
                sysParamName: [
                    {required: true, message: '参数名字不能为空', trigger: 'blur'}
                ],
                sysParamValue: [
                    {required: true, message: '参数值不能为空', trigger: 'blur'}
                ],
                remark: [
                    {validator: '', trigger: 'blur'}
                ]
            }
        };
    },
    methods: {

        initSysParamDetailPage(id) {

            let that = this;
            that.detailDialogVisible = true;
            that.detailDialogTitle = '新增';
            that.sysParamDto.id = null;
            if (id !== null) {

                // 查询参数信息
                that.querySysParamById(id);
                that.detailDialogTitle = '编辑';
                that.sysParamDto.id = id;
            }
        },
        closeSysParamConfigDetailPage() {

            this.validateNotPassField = [];
            // 重置表单
            this.$refs['sysParamConfigForm'].resetFields();
        },
        querySysParamById(id) {

            let that = this;
            let param = {

                id: id
            };
            let queryLoading = loading.loading('加载中...', 30);
            querySysParamById(param).then((res) => {

                queryLoading.close();
                if (res.code === global.response_status_success_obj) {

                    that.sysParamDto = res.data;
                } else {

                    message.error(res.msg);
                }
            }).catch(err => {

                message.error('请求失败,请稍后再试');
                console.error(err);
            });
        },
        saveOrModifySysParam() {

            this.validateNotPassField = [];
            this.$refs['sysParamConfigForm'].validate((valid) => {

                if (valid) {

                    saveOrModifySysParam(this.sysParamDto).then((res) => {

                        if (res.code === global.response_status_success_obj) {

                            this.detailDialogVisible = false;
                            this.$emit('querySysParamList');
                            if (this.sysParamDto.id === null) {

                                message.success('新增成功');
                            } else {

                                message.success('修改成功');
                            }
                        } else {

                            if (res.msg) {

                                message.error(res.msg);
                            }
                            if (res.data) {

                                // 如果后端校验不通过则会将校验不通过字段返回给前端
                                this.validateNotPassField = res.data;
                                this.$refs['sysParamConfigForm'].validate();
                            }
                        }
                    }).catch(err => {

                        message.error('请求失败,请稍后再试');
                        console.error(err);
                    });
                } else {

                    return false;
                }
            });
        }
    }
}
</script>

<style lang="scss" scoped>
</style>
